import Vue from 'vue'
import App from './App.vue'
import i18n from '../i18n'
import api from '../plugins/api-fetch'

Vue.use(api, {
  url: process.env.VUE_APP_API_SERVER_URL,
})
new Vue({
  el: '#app',
  i18n,
  render: h => h(App)
})
