import Vue from "vue";
import VueI18n from "vue-i18n";


Vue.use(VueI18n);

const messages = {
  en: {
    ...require("./src/locales/en/all.js"),

  },
  de: {
    ...require("./src/locales/de/all.js"),

  },

};

export default new VueI18n({
  locale: process.env.VUE_APP_I18NLOCALE || "de",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  messages,
});
