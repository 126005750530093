<template>
  <section class="py-lg-5 text-center container">
    <div class="questionBox row py-3" v-if="id">
      <div class="questionContainer col-lg-6 col-md-8 mx-auto"
        v-if="questionIndex < quiz.total_questions && started && !complete_set" v-bind:key="questionIndex">
        <header>
          <div class="progressContainer">
            <progress class="progress is-info is-small" :value="(index / total_quizzes) * 100" max="100">
              {{ (index / total_quizzes) * 100 }}%
            </progress>
            <p>{{ $t("quiz") }} {{ index + 1 }} / {{ total_quizzes }}</p>
          </div>
          <h3 class="title is-6">{{ quiz.name }}</h3>

          <div class="progressContainer">
            <progress class="progress is-info is-small" :value="(progress / quiz.total_questions) * 100" max="100">
              {{ (progress / quiz.total_questions) * 100 }}%
            </progress>
            <p>
              {{ ((progress / quiz.total_questions) * 100).toFixed(2) }}%
              {{ $t("complete") }}
            </p>
            <p>
              {{ $t("question") }} {{ progress + 1 }} /
              {{ quiz.total_questions }}
            </p>
          </div>
        </header>
        <!-- questionTitle -->
        <h4 class="titleContainer title">
          <b>{{ quiz.questions[questionIndex].number }}.</b>
          {{ quiz.questions[questionIndex].question_text }}
        </h4>
        <h5 v-if='quiz.questions[questionIndex].comment'>
          <span class="help">
            {{ quiz.questions[questionIndex].comment }}
          </span>
        </h5>

        <!-- quizOptions -->
        <div class="optionContainer my-5">
          <div v-if='quiz.questions[questionIndex].type == "text"'>
            <input type="text" required class="form-control" v-model="userResponses[questionIndex]" @keyup="updateText">
          </div>
          <div v-else>
            <div class="option" v-for="(response, index) in quiz.questions[questionIndex].answers"
              @click="selectOption(index)" :class="{ 'is-selected': userResponses[questionIndex] == index }"
              :key="index">
              <span :id="index">
                {{ response.answer_text }}
              </span>
            </div>
          </div>
        </div>

        <!--quizFooter: navigation and progress-->
        <footer class="questionFooter">
          <!--pagination-->
          <nav class="pagination" role="navigation" aria-label="pagination">
            <!-- back button -->
            <a class="btn btn-secondary later-btn" v-on:click="back()">{{ $t("back") }}
            </a>

            <!-- next button -->
            <a class="btn btn-primary" v-if="quiz.questions[questionIndex].type == 'text'"
              :class="noAnswer ? 'disabled' : 'is-active'" v-on:click="next()" :disabled="noAnswer">
              {{ $t("next") }}
            </a>
          </nav>
        </footer>
      </div>
      <div class="questionContainer" v-if="!started && loaded">
        <header>
          <h1 class="title is-6">{{ quiz.name }}</h1>

          <h3>{{ $t("Anleitung") }}</h3>
        </header>
        <div class="optionContainer">
          <div class="option" style="
              padding-left: 45px !important;
              padding-right: 45px !important;
            ">
            {{ quiz.instructions }}
          </div>
        </div>

        <!-- quizOptions -->
        <div class="optionContainer">
          <div class="center">
            <a class="btn btn-primary is-active" v-on:click="startQuiz()">
              {{ questionIndex ? $t("quiz continue") : $t("quiz start") }}
            </a>
          </div>
        </div>
      </div>
      <div v-if="(questionIndex >= quiz.questions.length && started) || complete_set
      " v-bind:key="questionIndex" class="quizCompleted has-text-centered">
        <div v-if="complete_set" class="questionContainer">
          <header>
            <h2 class="titleContainer title">
              {{ $t("set completed") }}
            </h2>
          </header>
          <div class="center2">
            <img class="check-img" src="./assets/check.svg" />
            <p v-if="quiz.complete_message">
              {{ quiz.complete_message }}
            </p>
          </div>
          <footer class="questionFooter">
            <!--pagination-->
            <nav class="pagination" role="navigation" aria-label="pagination">
              <a class="btn btn-secondary later-btn" v-on:click="back()" v-if="current_quiz"
                :disabled="questionIndex < 1">{{ $t("back") }}
              </a>
              <a v-if="total_quizzes" class="btn btn-primary is-active" href="/">
                {{ $t("finish quiz") }}
              </a>
            </nav>
          </footer>
        </div>
        <div class="questionContainer" v-if="quiz.total_questions && started && !complete_set">
          <header>
            <h2 class="titleContainer title" v-if="!completed && !quiz.complete_message">
              {{ $t("quiz completed") }}
            </h2>
            <div class="progressContainer">
              <progress class="progress is-info is-small" :value="((index + 1) / total_quizzes) * 100" max="100">
                {{ ((index + 1) / total_quizzes) * 100 }}%
              </progress>
              <p>{{ $t("quiz") }} {{ index + 1 }} / {{ total_quizzes }}</p>
            </div>
            <h2 class="titleContainer title" v-if="completed && !complete_set">
              {{ $t("quiz completed") }}
            </h2>
          </header>
          <div class="center2">
            <img class="check-img" src="./assets/check.svg" />
            <p v-if="quiz.complete_message">
              {{ quiz.complete_message }}
            </p>
            <div class="pt-5"></div>
          </div>
          <!--quizFooter: navigation and progress-->
          <footer class="questionFooter">
            <!--pagination-->
            <nav class="pagination" role="navigation" aria-label="pagination">
              <a class="btn btn-secondary later-btn" v-on:click="back()" :disabled="questionIndex < 1">{{ $t("back") }}
              </a>
              <a v-if="!complete_set" class="btn btn-primary is-active" v-on:click="nextQuiz()">
                {{ $t("next quiz") }}
              </a>
              <a v-else class="btn btn-primary is-active" href="/">
                {{ $t("finish quiz") }}
              </a>
            </nav>
          </footer>
        </div>
      </div>
    </div>
    <div class="questionContainer" v-else>
      <page-home />
    </div>
  </section>
</template>
<script>
//
import Vue from "vue";
import PageHome from "@/pages/home.vue";
import "./assets/scss/style.scss";
export default {
  components: {
    PageHome
  },
  name: "app",
  data() {
    return {
      quiz: {
        name: "",
        questions: []
      },
      loaded: false,
      started: false,
      form: {},
      id: "",
      options: ["dada"],
      questionIndex: 0,
      userResponses: [],
      isActive: false,
      completed: true,
      progress: 0,
      total_quizzes: 0,
      current_quiz: 0,
      general: [],
      index: 0,
      complete_set: false
    };
  },
  filters: {
    charIndex: function (i) {
      return String.fromCharCode(97 + i);
    }
  },
  mounted() {
    this.form = {};
    var pathname = window.location.pathname;
    this.id = pathname.substring(1);
    this.total_quizzes = 0;
    // get general information about quizzes
    if (this.id) {
      this.get("/fill/" + this.id)
        .then(data => {
          if (data.current_id) {
            this.general = data;
            this.current_quiz = this.general.current_id;
            this.total_quizzes = this.general.quizzes.length;
            let ok = false;
            while (!ok) {
              if (this.index < this.total_quizzes) {
                if (this.general.quizzes[this.index].id == data.current_id) {
                  ok = true;
                } else {
                  this.index++;
                }
              } else {
                this.index = -1;
                ok = true;
              }
            }
            this.getQuiz(data.current_id);
          } else {
            this.complete_set = true;
            this.index = data.total_quizzes;
          }
        })
        .catch(() => {
          this.loaded = true;
          this.started = true;
          this.index = this.total_quizzes = this.general.quizzes
            ? this.general.quizzes.length
            : 0;
        });
    }
  },
  methods: {
    startQuiz: function () {
      this.started = true;
    },
    nextQuiz: function () {
      this.started = false;
      this.index++;
      this.current_quiz = this.general.quizzes[this.index].id;
      if (this.index + 1 == this.total_quizzes) {
        this.complete_set = true;
      }
      console.log(this.general.quizzes);
      this.complete_set = false;
      this.completed = false;
      this.questionIndex = 0;
      this.progress = 0;
      this.getQuiz(this.general.quizzes[this.index].id);
    },
    getQuiz: function (id) {
      this.get("/questions/" + this.id + "/" + id)
        .then(data => {
          this.quiz = data;
          this.progress = this.quiz.answered_questions;
          if (this.progress == this.quiz.total_questions) {
            this.completed = true;
            this.started = true;
            this.questionIndex = this.progress;
          } else {
            this.loaded = true;
            this.questionIndex = this.progress;
          }
          this.userResponses = Array(this.quiz.total_questions).fill(null);
        })
        .catch(() => {
          this.loaded = true;
          this.started = true;
        });
    },
    selectOption: function (index) {
      Vue.set(this.userResponses, this.questionIndex, index);
      this.next();
    },
    updateText: function () {
    },
    next: function () {
      if (!this.noAnswer) {
        //color o
        let elem = document.getElementsByClassName("is-selected");
        for (let el of elem) {
          el.classList.add("selected-blue");
        }
        if (this.questionIndex <= this.quiz.total_questions) {
          if (this.quiz.questions[this.questionIndex].type == 'text') {
            this.form.answer_text = this.userResponses[this.questionIndex];
            this.form.answer_id = null;
          } else {
            this.form.answer_text = null;
            this.form.answer_id = this.quiz.questions[this.questionIndex].answers[
              this.userResponses[this.questionIndex]
            ].id;
          }
          this.form.question_id = this.quiz.questions[this.questionIndex].id;
          this.post(
            "/questions/" + this.id + "/" + this.current_quiz,
            this.form
          )
            .then(() => {
              if (!this.complete_set) {
                for (let el of elem) {
                  el.classList.remove("selected-blue");
                }
              }
              this.progress++;
              this.questionIndex++;
            })
            .catch(error => {
              alert(error.message);
            });
        }
      }
      if (this.questionIndex == this.quiz.total_questions - 1) {
        if (this.index + 1 == this.general.quizzes.length) {
          this.complete_set = true;
        }
      }
    },

    back: function () {
      if (this.questionIndex > 0) {
        this.questionIndex--;
        this.progress--;
        this.complete_set = false;
      } else {
        this.started = false;
      }
    }
  }, computed: {
    noAnswer: function () {
      return this.userResponses[this.questionIndex] == null ? true :
        this.quiz.questions[this.questionIndex].type == 'text' && !this.userResponses[this.questionIndex] ? true : false;
    }
  }
};
</script>
